
  .header-nav-box.show-mobile-menu {
    display: flex;
  }
  .main-navigation-link-text{
    display: flex;
    gap: 21px;
    align-items: center;
    /* justify-content: space-between; */
  }

  .mobile-main-navigation-link-text {
    position: absolute;
    top: 268px;
    left: 40px;
    z-index: 1000;
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-direction: column;
    max-width: 400px;
    width: 100%;
  }
  .mobile-icon-btn{
    position: absolute;
    top: 127px;
    left: 36px;
    font-size: 28px;
  }
  
  .mobile-align-items-center {
    width: 100%;
    height: 100vh;
    /* background-color: white; */
  }
  
  .mobile-header-logo-main-link {
    display: none;
  }
  
  .mobile-header-action-box {
    display: none;
  }
  .mobile-main-navigation-link-text1{
    position: absolute;
    top: 194px;
    left: 40px;
    z-index: 1000;
  }

  .dropdown-container {
    position: absolute;
    top: 33px;
    left: 0;
    width: 100vw;
    z-index: 1000;
    background-color: white;
    box-shadow:
      0px 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    padding: 18px 0px 0px 28px;
    /* background-color: #fff; */
    /* display: block; */
    margin-top: 5px;
    /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
  }

  .dropdown-menu {
    left: 0 ; 
    right: auto ; 
    transform: translate3d(0, 0, 0) ;
  }
  
  .dropdown-custom {
    left: 0 ; 
    right: auto ;
    top: 100%;
    transform: none ;
  }

  .header-logo-img{
    width: 92px;
    height: 71px;
  }
  
  
  /* Show the mobile menu when the window width is smaller than 992px */
  @media (max-width: 992px) {
    .dropdown-image{
      display: none;
    }
    .header-nav-box {
      flex-direction: column;
      display: none; 
    }
    .dropdown-container {
    position: static;
    }
    .header-nav-box.show-mobile-menu {
      display: flex; /* Show menu when toggled open */
    }
  
    .menu-item {
      width: 100%; /* Full width on mobile */
      padding: 10px 0;
    }
  
    .dropdown-content {
      display: flex;
      flex-direction: column;
    }
  
    .menu-column {
      margin: 10px 0;
    }
  }

  .show-mobile-menu {
    display: flex;
    flex-direction: column;
  }
  
  .nav-link {
    width: 100%;
  }
  
  .mobile-dropdown {
    border-radius: 5px;
    background-color: white;
  }
.dropdown-image{
  padding: 10px;
  margin-left: 10px;
  text-align: center;
  
}
  /* @media (max-width: 768px) {
    .header-nav-box {
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
    }
  
    .mobile-dropdown {
      width: 100%;
      margin: 0 auto;
      padding: 10px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }
  } */

.nav-item {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%; 
  left: 0;
  z-index: 1000;
  display: none; 
  width: 100%; 
  padding: 10px 0;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdown-item {
  max-width: max-content;
  padding: 8px 16px;
  color: #333;
  text-decoration: none;
  display: block;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
  color: #000;
}

.nav-item:hover .dropdown-menu {
  display: block;
}

.dropdown-menu .list-unstyled {
  margin: 0;
  padding: 0;
}

.dropdown-menu .list-unstyled li {
  padding: 5px 16px;
}

.nav-item.show .dropdown-menu {
  display: block;
}

@media (max-width: 768px) {
  .dropdown-menu {
    position: static;
    width: 100%;
    border: none;
    box-shadow: none;
  }
  .dropdown-image{
    display: none;
  }
}

  
  