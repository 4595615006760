@font-face {
  font-family: "AdihausDIN";
  src: url("../fonts/AdihausDIN.woff2") format("woff2"),
       url("../fonts/AdihausDIN.woff") format("woff"),
       url("../fonts/AdihausDIN.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "AdihausDIN", "Helvetica", "Arial", sans-serif;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.profile-wrapper{
    width: 100%;
    height: 100vh;
}
.profile-container{
    width: 100%;
    display: flex;
    /* align-items: center;
    justify-content: flex-start; */
    gap: 40px;
    padding-top: 125px;
    margin: 0px 30px;
}
.profile-name{
    max-width: 500px;
    width: 100%;
    display: flex;
    gap: 20px;
    flex-direction: column;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 25px;
}
.profile-user{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 20px;
    border-bottom: 1px solid #8a8686;
}
.profile-icon-user{
    font-size: 100px;
    margin-bottom: 12px;
}
.profile-btn{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 14px;
}
.profile-btn button{
    padding: 7px 8px;
    border: 1px solid #d6d3d3;
    border-radius: 5px;
    font-weight: bold;
    display: flex;
    font-size: 16px;
}
.profile-btn button:hover{
    background-color: orangered;
    color: white;
}
.profile-login{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.logout-section{
    width: 100%;
}
.logout-section h3{
    width: 91%;
    font-size: 31px;
    font-weight: 900;
    color: orange;
}
.logout-section button{
    width: 91%;
    margin-top: 15px;
    padding: 8px 0px;
    background-color: orangered;
    border: none;
    color: white;
    font-size: 20px;
    font-weight: 600;
}
.logout-section p{
    text-align: center;
    margin-top: 15px;
    font-size: 21px;
}
.profile-header1 {
    width: 100% !important;
}
.gender-options {
    display: flex;
    gap: 10px;
    margin-bottom: 19px;
    margin-top: 10px;
  }
  
  .gender-options label {
    display: flex;
    align-items: center;
  }
  
  .gender-options input {
    margin-right: 5px;
  }
  /* .add-address-container {
    font-family: Arial, sans-serif;
  } */
  
  h3 {
    color: #666;
    font-size: 22px;
    margin-bottom: 15px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  form input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  form button {
    padding: 12px;
    background-color: #0059ff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  form button:hover {
    background-color: #0044cc;
  }
  .select-address-container {
    padding: 20px;
    /* font-family: Arial, sans-serif; */
  }
  
  h2 {
    color: #666;
  }
  
  .address-list {
    margin: 20px 0;
  }
  
  .address-item {
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: space-between;
  }
  
  .address-item p {
    margin: 5px 0;
    color: #333;
  }
  
  .add-address-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 15px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    font-size: 18px;
    color: #666;
    transition: box-shadow 0.3s ease;
  }
  
  .add-address-btn:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .address-actions {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
  
  .edit-icon, .delete-icon {
    cursor: pointer;
    color: #007bff;
  }
  
  .delete-icon {
    color: #dc3545;
  }
  
  .edit-icon:hover, .delete-icon:hover {
    opacity: 0.8;
  }
  
@media screen and (max-width:1050px){
    .profile-container{
        width: 100%;
        flex-direction: column;
        margin: auto;
        align-items: center;
    }
    .login-form{
        margin-top: 0px;
        margin-bottom: 20px;
    }
}
@media screen and (max-width:500px){
    .order-data{
        display: block;
        margin-top: 5px;
    }
    .address-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap:0px
    }
}