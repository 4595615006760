body {
    margin: 0;
    /* font-family: Arial, sans-serif; */
    background-color: #f5f5f5;
  }
  
  .container1 {
    max-width: 600px;
    margin: 50px auto;
    background: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
  }
  
  .title1 {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  .add-btn1 {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    margin-bottom: 20px;
    transition: background-color 0.3s ease;
  }
  
  .add-btn1:hover {
    background-color: #0056b3;
  }
  
  .address-card1 {
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .name1 {
    font-size: 18px;
    font-weight: bold;
    color: #444;
  }
  
  .address1 {
    font-size: 14px;
    color: #555;
  }
  
  .phone1 {
    font-size: 14px;
    color: #666;
  }
  
  .actions1 {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
  
  .icon1 {
    font-size: 18px;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .edit-icon1 {
    color: #007bff;
  }
  
  .edit-icon1:hover {
    transform: scale(1.1);
  }
  
  .delete-icon1 {
    color: #e74c3c;
  }
  
  .delete-icon1:hover {
    transform: scale(1.1);
  }
  
  @media (max-width: 768px) {
    .container1 {
      padding: 15px;
    }
  
    .title1 {
      font-size: 20px;
    }
  
    .add-btn1 {
      font-size: 14px;
    }
  
    .address-card1 {
      padding: 10px;
    }
  
    .name1 {
      font-size: 16px;
    }
  
    .address1,
    .phone1 {
      font-size: 12px;
    }
  }