body {
    margin: 0;
    /* font-family: Arial, sans-serif; */
    background-color: #f4f4f4;
  }
  
  .containerSection {
    display: flex;
    height: 100vh;
  }
  
  /* Sidebar */
  .sidebar {
    width: 130px;
    background-color: #ffffff;
    border-right: 1px solid #ddd;
    box-sizing: border-box;
    overflow-y: auto;
  }
  
  .sidebar h3 {
    width: 70%;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    margin:'auto'
  }
  
  .Subdesign-section {
    position: absolute;
    display: flex;
    gap: 10px;
    top: 0px;
    left: 120px;
    width: 27%;
    height: 100vh;
    background-color: white;
  }
  
  /* Design Section */
  .design-section{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-bottom: 20px;
    z-index: 10000;
    padding: 40px 20px 20px 20px;
  }
  .design-section1{
    position: absolute;
    z-index: 1000000;
    height: 100%;
      background-color: white;
  }
  
  .text-section,.logo-uploader{
    gap: 10px;
    margin-bottom: 20px;
    z-index: 10000;
    padding: 38px 20px 20px 20px;
  }
  
  .color-selection{
    z-index: 10000;
  }
  
  .design-button {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    background-color: #f8f9fa;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    transition: all 0.3s ease;
  }
  
  .design-button:hover,
  .design-button.active {
    background-color: #003366;
    color: white;
  }
  
  /* Color Picker */
  .color-picker {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .color-box {
    width: 30px;
    height: 30px;
    border: 1px solid #ddd;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .color-box:hover {
    transform: scale(1.1);
  }
  
  /* Text Editor */
  .text-editor input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  /* Logo Uploader */
  .logo-uploader input {
    margin-top: 10px;
    padding: 25px 20px 20px 20px;
  }
  
  /* 3D Viewport */
  .main-viewport {
    flex-grow: 1;
    background-color: #e6e6e6;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .canvas-container {
    width: 60%;
    height: 100%;
  }
  
  /* Buttons */
  .button-style {
    padding: 8px 12px;
    background-color: #003366;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 10px;
    font-size: 14px;
  }
  
  .button-style:hover {
    background-color: #002244;
  }
  
  .text-editor {
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .text-editor h3 {
    /* font-family: 'Arial', sans-serif; */
    font-size: 18px;
    margin-bottom: 15px;
    color: #333;
  }
  
  .input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  
  .input-group label {
    /* font-family: 'Arial', sans-serif; */
    font-size: 14px;
    font-weight: 500;
    color: #555;
    margin-bottom: 8px;
  }
  
  .input-group input {
    padding: 10px 12px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
    transition: all 0.3s ease-in-out;
    width: 90%;
  }
  
  .input-group input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.4);
  }
  
  .input-group input::placeholder {
    color: #aaa;
    font-style: italic;
  }
  
  .text-editor {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .color-picker {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
    /* max-height: 200px;
    overflow-y: auto; */
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  
  .color-box {
    width: 41px;
    height: 41px;
    border: 1px solid #d3d2d2;
    /* border-radius: 50%; */
    cursor: pointer;
    position: relative;
    background-color: white;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: border 0.3s ease;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }
  
  .color-box .inner-color {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: inherit;
  }
  
  .color-box:hover {
    border: 1px solid black;
  }
  
  .color-box.selected {
    border: 3px solid blue;
  }
  
  .color-box .checkmark {
    position: absolute;
    bottom: -5px;
    right: -5px;
    font-size: 14px;
    color: white;
    background-color: blue;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
  
  .pattern-section {
    display: flex;
    gap: 15px;
    justify-content: center;
    flex-wrap: wrap;
    
  }
  
  .pattern-thumbnail {
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }
  
  .pattern-thumbnail:hover {
    transform: scale(1.1);
    box-shadow: 0 0 20px rgba(0,0,0,0.2);
  }

  .continue-button {
    background: linear-gradient(135deg, #0d6efd, #ff758c);
    color: white;
    font-size: 18px;
    font-weight: bold;
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 4px 10px rgba(255, 117, 140, 0.3);
    outline: none;
  }
  
  .continue-button:hover {
    background: linear-gradient(135deg, #ff758c, #ff4d79);
    box-shadow: 0px 6px 15px rgba(255, 117, 140, 0.5);
    transform: translateY(-2px);
  }
  
  .continue-button:active {
    transform: translateY(1px);
    box-shadow: 0px 3px 8px rgba(255, 117, 140, 0.2);
  }
  
  