/* 
@media (min-width: 768px) {
    .cart-item {
      flex-direction: row;
    }
  
    .cart-item img {
      width: 25%;
    }
  
    .cart-item-details {
      width: 55%;
    }
  
    .cart-item-remove {
      margin-top: 0;
    }
  }
  
  @media (max-width: 768px) {
    .cart-item {
      flex-direction: column; 
      align-items: center; 
    }
  
    .cart-item img {
      width: 60%;
      margin-bottom: 10px; 
    }
  
    .cart-item-details {
      width: 100%; 
      text-align: center; 
    }
  
    .cart-item-remove {
      margin-top: 10px; 
      width: 100%; 
    }
  } */




  /* Container for the table */
.table-container {
  max-width: 1000px;
  margin: 20px auto;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background: #ffffff;
}

/* Table styling */
.product-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.product-table th,
.product-table td {
  padding: 12px 10px;
  text-align: center;
  border: 1px solid #ddd;
}

.product-table th {
  background-color: #f8f9fa;
  color: #333;
  font-weight: bold;
}

.product-table td {
  font-size: 14px;
  color: #555;
}

.product-table input,
.product-table select {
  width: 90%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.product-table input:focus,
.product-table select:focus {
  border-color: #007bff;
  outline: none;
}

/* Quantity control */
.quantity-control {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.quantity-control button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 12px;
  font-size: 14px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.quantity-control button:hover {
  background-color: #0056b3;
}

.quantity-control span {
  font-size: 16px;
  font-weight: bold;
}

/* Add row button */
.add-row-btn {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 15px;
  border-radius: 50%;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.add-row-btn:hover {
  background-color: #218838;
  transform: scale(1.1);
}


.table-wrapper {
  overflow-y: auto; /* Vertical scrolling for the table rows */
  margin-bottom: 1rem;
}

.table-scroll {
  overflow-x: auto; /* Enable horizontal scrolling */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on mobile devices */
  padding-bottom: 57px;
}

.table-scroll::-webkit-scrollbar {
  display: none; /* Hide horizontal scrollbar */
}

.table-scroll {
  scrollbar-width: none; /* Firefox: Hide scrollbar */
}

.table-auto {
  min-width: 800px; /* Prevent shrinking on smaller viewports */
  border-collapse: collapse;
}

.table-auto th,
.table-auto td {
  white-space: nowrap; /* Prevent text wrapping */
  padding: 8px;
  text-align: left;
}

.table-auto th {
  font-weight: bold;
  background-color: #f9fafb;
}

@media (max-width: 768px) {
  .table-wrapper {
    max-height: 300px; /* Adjust height for smaller devices */
  }
}


@media (max-width: 768px) {
  .table-wrapper {
    max-height: 300px; 
  }
}


/* Responsive Design */
@media (max-width: 768px) {
  .product-table th,
  .product-table td {
    padding: 10px 8px;
    font-size: 12px;
  }

  .product-table input,
  .product-table select {
    width: 85%;
    font-size: 12px;
    padding: 6px;
  }

  .quantity-control button {
    padding: 4px 8px;
    font-size: 12px;
  }

  .quantity-control span {
    font-size: 14px;
  }

  .add-row-btn {
    padding: 12px;
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .table-container {
    padding: 5px;
  }

  .product-table {
    font-size: 10px;
  }

  .quantity-control {
    gap: 5px;
  }

  .quantity-control button {
    padding: 3px 6px;
    font-size: 10px;
  }
}
  