.slider-container {
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }
  
  .slider-heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .slide {
    padding: 10px;
    box-sizing: border-box;
  }
  
  .sport-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .sport-name {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    border-top: 2px solid black;
    padding-top: 5px;
  }
  
  .slick-prev:before, .slick-next:before {
    color: black;
    font-size: 24px;
  }
  
  @media (max-width: 768px) {
    .slider-heading {
      font-size: 20px;
    }
  
    .sport-name {
      font-size: 16px;
    }
  }
  