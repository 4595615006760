.payment-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .payment-modal-content {
    background: #ffffff; 
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    padding: 20px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3); 
    animation: fadeIn 0.3s ease-out;
    text-align: center;
  }
  
  .payment-modal-content h2 {
    margin-bottom: 15px;
    font-size: 24px;
    color: #2c3e50;
  }
  
  .payment-modal-content p {
    margin-bottom: 20px;
    font-size: 16px;
    color: #7f8c8d;
  }
  
  .pay-now-btn {
    background: #3498db;
    color: #ffffff;
    padding: 12px 20px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    margin: 5px;
    transition: background 0.3s;
  }
  
  .pay-now-btn:hover {
    background: #2980b9; 
  }
  
  .close-btn {
    background: #e74c3c;
    color: #ffffff;
    padding: 12px 20px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    margin: 5px;
    transition: background 0.3s;
  }
  
  .close-btn:hover {
    background: #c0392b;
  }