.section2-one-btn {
  width: 100%;
  background-color: #283854;
  color: white;
  padding: 8px 0px;
  border-radius: 7px;
  font-weight: 700;
  border: none;
  font-size: 17px;
  margin-top: 5px;
}

.section-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ebf5e2 !important;
  border-radius: 7px;
  padding: 8px 0px;
  margin-top: 5px;
}

.section-add-sub {
  width: 100%;
  background-color: #ebf5e2;
  color: black;
  font-size: 22px;
  font-weight: 700;
  border: none;
}

.product-detail-main {
  margin-top: 30px;
}

.slick-slider .slick-track
{
  min-width: 102%;
  max-height: fit-content;
}

.slick-slider {
  width: 100%;
  overflow: visible; /* Ensure slides are visible */
}

/* .slick-track {
  display: flex;
  align-items: center;
} */

.product-image {
  width: 128px;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}

@media (max-width: 768px) {
  .product-image {
    width: 100%;
    height: 207px;
    margin-bottom: 10px;
    object-fit: fill;
  }
}



@media (max-width: 992px) {
  .product-detail-main {
    margin-top: 125px;
  }
}