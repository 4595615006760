/* 
.page-wrapper {
    display: flex;
    font-family: "Arial", sans-serif;
    min-height: 100vh;
  }
  
  .filter-section {
    position: relative;
    top: 0;
    left: 0;
    width: 300px;
    height: 100%;
    background: #ffffff;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    overflow-y: auto;
    z-index: 1000;
  }
  
  .filter-title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #6a1b9a;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .filter-input {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    color: #333;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 15px;
    transition: border-color 0.3s ease;
  }
  
  .filter-input:focus {
    border-color: #6a1b9a;
    outline: none;
    box-shadow: 0px 0px 5px rgba(106, 27, 154, 0.3);
  }
  
  .filter-button {
    background: linear-gradient(to right, #6a1b9a, #4a148c);
    color: white;
    font-size: 1rem;
    padding: 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
    width: 100%;
    margin-top: 10px;
  }
  
  .filter-button:hover {
    background: linear-gradient(to right, #4a148c, #6a1b9a);
  }
  
  .filter-button:disabled {
    background: #d1c4e9;
    cursor: not-allowed;
  }
  
  .filter-message {
    font-size: 0.9rem;
    color: #6a1b9a;
    margin-top: 15px;
  }
  
  .filter-label {
    font-size: 1rem;
    font-weight: 500;
    color: #555;
    margin-bottom: 5px;
    display: block;
  }
  
  .content-section {
    margin-left: 320px;
    padding: 20px;
    flex-grow: 1;
  }
   */

.filter-section {
  position: relative;
  top: 0;
  left: 0;
  width: 340px;
  /* height: 100%; */
  background: #ffffff;
  padding: 10px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
}

.filter-container {
  background: #ffffff;
  border-radius: 10px;
  width: 320px;
  /* font-family: "Arial", sans-serif; */
  padding: 10px;
}

.filter-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.category-boxes,
.subcategory-boxes {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
}

.category-box,
.subcategory-box {
  width: calc(50% - 5px);
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f7f7f7;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.category-box.highlighted {
  /* background-color: #ffd700;
  color: white;
  border-color: #e5c100; */
  font-weight: bold;
  color: #918f8f;
}

.category-box:hover,
.subcategory-box:hover {
  background-color: #007bff;
  color: white;
  border-color: #0056b3;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.category-box.selected,
.subcategory-box.selected {
  background-color: #4caf50;
  color: white;
  border-color: #388e3c;
}

.section-title {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.filter-input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 15px;
  transition: border-color 0.3s ease;
}

.filter-input:focus {
  border-color: #6a1b9a;
  outline: none;
  box-shadow: 0 0 5px rgba(106, 27, 154, 0.5);
}

.filter-button {
  background: linear-gradient(to right, #6a1b9a, #4a148c);
  color: white;
  font-size: 1rem;
  padding: 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
  width: 100%;
}

.filter-button:hover {
  background: linear-gradient(to right, #4a148c, #6a1b9a);
  transform: translateY(-2px);
}

.price-range {
  margin-bottom: 20px;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 8px;
}

.dropdown-header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: #555;
  margin-bottom: 10px;
}

.filter-label {
  font-size: 1rem;
  font-weight: 500;
  color: #555;
  margin-bottom: 5px;
  display: block;
}

.category-section,
.subcategory-section,
.price-section {
  margin-bottom: 20px;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
}


/* Product */

.content-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #f9f9f9;
  /* font-family: Arial, sans-serif; */
}

.page-layout {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.page-layout-wrapper {
  display: flex;
  flex: 1;
  width: 100%;
}

/* .sidebar {
  width: 340px;
  background-color: #ffffff;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  overflow-y: auto;
  flex-shrink: 0;
  position: sticky; 
  top: 0;
  height: 100vh;
} */

.main-content {
  flex: 1;
  background-color: #ffffff;
  padding: 20px;
  overflow-y: auto;
}

.product-listing {
  width: 100%;
}

.product-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.product-card {
  flex: 1 1 calc(33.333% - 20px);
  max-width: calc(33.333% - 20px);
  height: 450px;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #eee;
  padding: 10px;
  transition: transform 0.3s ease;
}

.product-card:hover {
  transform: translateY(-5px);
}

.product-image-wrapper {
  height: 80%;
}

.product-link {
  height: 100%;
}

.product-mrp .mrp {
  text-decoration: line-through;
  color: gray;
  font-style: italic;
}

@media screen and (max-width: 1024px) {
  .product-card {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
}

@media screen and (max-width: 768px) {
  .product-card {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .filter-section {
    width: 270px;
  }

  .filter-container {
    width: 250px;
  }
}

.product-box {
  text-align: center;
}

.product-image-wrapper {
  position: relative;
}

.product-image {

  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease;
}

.hover-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.product-image-wrapper:hover .hover-image {
  opacity: 1;
}

.product-info {
  margin-top: 10px;
}

.product-name {
  font-weight: bold;
  font-size: 1rem;
  color: #333;
  text-decoration: none;
}

.product-price,
.product-mrp {
  font-size: 0.9rem;
  color: #666;
}

@media screen and (max-width: 570px) {

  .category-box,
  .subcategory-box {
    width: calc(100% - 5px);
  }

  .filter-section {
    padding: 0px;
    width: 233px;
  }

  .filter-container {
    width: 233px;
  }

  .section-title {
    font-size: 15px;
  }
  .main-content {
    padding: 7px;
  }
}

@media screen and (max-width: 470px) {
  .filter-section {
    width: 200px;
  }

  .filter-container {
    width: 200px;
  }

  .section-title {
    font-size: 15px;
  }
  .section-title {
    font-size: 13px;
    font-weight: bold;
  }
}